<script>
import appConfig from "@/app.config";

import {mapGetters,mapState} from "vuex";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum
} from "vuelidate/lib/validators";
import {ProductCategory} from "@/state/helpers";
import {get_acsrf_token, with_acrf_token} from "@/methods";


export default {
  components: {

  },
  computed: {
    ...mapGetters([
      'getPersonaAvatar',
      'getPersonaName'
    ]),
    ...mapState({
      setPersonaName: 'setPersonaName'
    })
  },
  data() {
    return {
      avatar_url: process.env.VUE_APP_ROOT_API + 'v1/@me/avatar',
      original_avatar_url: process.env.VUE_APP_ROOT_API + 'v1/@me/avatar',
      display_name: ''
    };
  },
  validations: {

  },
  created() {
    this.display_name = this.getPersonaName();
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    saveDisplayName: function() {
      let input = this.$refs.dni;
      if(!this.display_name || !this.display_name.match(/^[0-9a-zA-Z]+$/)) {
        input.classList.add('is-invalid');
      } else {
        input.classList.remove('is-invalid');
        input.classList.add('disabled');
        with_acrf_token().then((r) => {
          let payload = {
            acsrf_token: r.acsrf_token,
            display_name: this.display_name
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/update-display-name', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
              .then(response => {
                input.classList.remove('disabled');
                if(response.ok || response.status === 400){
                  return response.json();
                } else {

                  if(response.status === 429) {
                    this.$swal({
                      icon: 'warning',
                      text: this.$t('error.server.ratelimit.message'),
                      title: this.$t('error.server.ratelimit.title')
                    });
                    return;
                  } else
                    throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                }
              })
              .then(data => {
                if(!data) return;
                if(data.status) {
                  this.display_name = data.display_name;
                  this.$store.commit('setPersonaName', this.display_name);
                  input.classList.add('is-valid');
                  setTimeout(function() {
                    input.classList.remove('is-valid');
                  }, 750);
                } else {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('account.persona.display_name.error')
                  });
                }
              })
              .catch(error => {
                console.log(`[ERROR] ${error}`);
                this.$swal({
                  icon: 'error',
                  text: this.$t('account.persona.display_name.error')
                });
              });
        }).catch(error => {
          console.log('[ERROR] Failed to request acrf token');
          this.$swal({
            icon: 'error',
            text: this.$t('error.server.generic.message')
          });
        });
      }
    },
    async avatarReplacement(event) {
      let files = event.target.files;
      if(files.length === 0) return;
      let file = files[0];

      this.original_avatar_url = this.avatar_url; // keep old avatar in case of error
      this.avatar_url = URL.createObjectURL(file);

      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/avatar-upload`, {
          method: 'GET',
          credentials: 'include'
        });
        let data;
        if(!response.ok) {
          if(response.status === 400) {
            data = await response.json();
            if(data.error === 'exhausted') {
              this.$swal({
                icon: 'warning',
                text: this.$t('error.gryphon.quota.message'),
                title: this.$t('error.gryphon.quota.title')
              });
              throw new Error('gryphon blocked');
            }
          } else {
            this.$swal({
              icon: 'error',
              text: this.$t('account.persona.avatar.error.request')
            });
            return
          }
        } else {
          data = await response.json();
          file.resource_id = data.resource_id;
          if(data.storage_warning) {
            let result = await this.$swal({
              icon: 'warning',
              text: this.$t('error.gryphon.quota_warning.message'),
              title: this.$t('error.gryphon.quota_warning.title'),
              showCancelButton: true,
              confirmButtonText: this.$t('error.gryphon.quota_warning.confirm'),
              confirmButtonColor: '#f46a6a',
              cancelButtonColor: '#c3cbe4',
            });
            if(!result.isConfirmed) throw new Error('cancelled');
          }
        }

        let upload_url = data.upload_url;
        let upload_token = data.upload_token;

        let payload, acsrf_token;
        payload = new FormData();
        payload.append('file', file);
        payload.append('upload_token', upload_token);

        response = await fetch(data.upload_url, {
          method: 'POST',
          body: payload,
          credentials: 'include'
        });
        if(!response.ok) throw new Error('upload failed');
        data = await response.json();
        acsrf_token = await get_acsrf_token();
        payload = {
          acsrf_token: acsrf_token,
          avatar_url: data.resource.url
        };

        response = await fetch( process.env.VUE_APP_ROOT_API + 'v1/@me/update-avatar', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        this.avatar_url = data.resource.url;
        this.original_avatar_url = data.resource.url;
        document.getElementById('app-avatar').src = data.resource.url;
        this.$store.commit('setPersonaAvatar', data.resource.url);

      } catch(e) {
        console.log(`[ERROR] ${e}`);
        this.$swal({
          icon: 'error',
          text: this.$t('account.persona.avatar.error.request')
        });
      }
    },
    avatar_replacement: function(event) {
      let that = this;
      let files = event.target.files;
      if(files.length === 0) return;
      let file = files[0];

      this.original_avatar_url = this.avatar_url; // keep old avatar in case of error
      this.avatar_url = URL.createObjectURL(file);

      fetch(process.env.VUE_APP_ROOT_API + 'v1/account/avatar-upload', {credentials: 'include'})
        .then(response => {
          if(response.ok) {
            return response.json();
          } else {
            that.avatar_url = that.original_avatar_url;
            if(response.status == 403) {
              this.$swal({
                icon: 'error',
                text: this.$t('account.persona.avatar.error.request')
              });
            } else if(response.status == 429) {
              this.$swal({
                icon: 'warning',
                text: this.$t('error.server.ratelimit.message'),
                title: this.$t('error.server.ratelimit.title')
              });
            } else
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          let upload_url = data.upload_url;
          let upload_token = data.upload_token;

          let payload = new FormData();
          payload.append('file', file);
          payload.append('upload_token', upload_token);

          fetch(upload_url, {
            method: 'POST',
            body: payload,
            credentials: 'include'
          })
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            let resource_url = data.resource.url;

            with_acrf_token().then((r) => {
              let payload = {
                acsrf_token: r.acsrf_token,
                avatar_url: resource_url
              };
              fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/update-avatar', {
                method: 'POST',
                body: JSON.stringify(payload),
                credentials: 'include'
              })
                .then(response => {
                  if(response.ok || response.status === 400){
                    return response.json();
                  } else {
                    if(response.status === 429) {
                      this.$swal({
                        icon: 'warning',
                        text: this.$t('error.server.ratelimit.message'),
                        title: this.$t('error.server.ratelimit.title')
                      });
                    } else
                      throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                  }
                })
                .then(data => {
                  if(data.status) {
                    that.avatar_url = resource_url;
                    that.original_avatar_url = resource_url;
                    document.getElementById('app-avatar').src = resource_url;
                    this.$store.commit('setPersonaAvatar', resource_url);
                  } else {
                    this.$swal({
                      icon: 'error',
                      text: this.$t('account.persona.avatar.error.request')
                    });
                  }
                })
                .catch(error => {
                  console.log(`[ERROR] ${error}`);
                  this.$swal({
                    icon: 'error',
                    text: this.$t('account.persona.avatar.error.request')
                  });
                });
            }).catch(error => {
              console.log('[ERROR] Failed to request acrf token');
              this.$swal({
                icon: 'error',
                text: this.$t('error.server.generic.message')
              });
            });
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            this.$swal({
              icon: 'error',
              text: this.$t('account.persona.avatar.error.cdn_generic')
            });
          });

        })
        .catch(error => {
          if(error !== 'cancelled') this.$toast.error(this.$t('error.server.generic.message'));
        });

    }
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-4 justify-content-center text-center">
      <div class="row">
        <div class="col-xl-12 col-sm-6 justify-content-center">
          <img :src="avatar_url" alt="User avatar" class="rounded-circle" style="height: 96px !important; width: 96px !important; border: 2px solid #32394e !important;">
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-xl-12 col-sm-6 justify-content-center">
          <button class="btn btn-primary btn-sm" @click="$refs.avatar_upload.click()">
            <i class="fad fa-upload"></i> {{ $t('account.persona.avatar.upload') }}
            <input type="file" accept="image/*" ref="avatar_upload" style="display: none;" v-on:change="avatarReplacement($event)">
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-8 pt-2">
      <div class="form-group">
        <label for="cf-display-name">
          {{ $t('account.persona.display_name.label') }}
          <br>
          <small class="text-muted">
            {{ $t('account.persona.display_name.description') }}
          </small>
        </label>

        <div class="input-group mb-3">
          <input
              id="cf-display-name"
              type="text"
              class="form-control"
              ref="dni"
              v-model="display_name"
              :placeholder="$t('account.persona.display_name.label')"
          />
          <div class="input-group-append">
            <button
                class="btn btn-primary d-inline-block"
                style="min-width: 5rem;"
                @click="saveDisplayName"
            >
              <i class="fad fa-user-edit"></i> {{ $t('account.persona.display_name.button') }}
            </button>
          </div>
          <div class="invalid-feedback">
            <span>
              {{ $t('account.persona.display_name.rules') }}
            </span>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>